@import '../../../settings/Settings';

.InsertLabelNamesPopup {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  align-self: stretch;
  flex: 1;
  min-height: 400px;

  .LeftContainer {
    width: 50px;
    align-self: stretch;
    border-right: solid 1px $darkThemeFirstColor;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    padding: 3px 0;

    .ImageButton {
      transition: transform 0.3s;

      img {
        filter: brightness(0) invert(1);
      }

      &:hover {
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &.active {
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .RightContainer {
    align-self: stretch;
    flex: 1;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;

    .Message {
      align-self: stretch;
      color: white;
      font-size: 15px;
      padding: 30px 40px;
      border-bottom: solid 1px $darkThemeFirstColor;
    }

    .LabelsContainer {
      align-self: stretch;
      flex: 1;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;

      color: white;

      .ImageButton {
        transition: transform 0.3s;

        img {
          filter: brightness(0) invert(1);
          user-select: none;
        }

        &:hover {
          background-color: transparent;
        }

        &:not(.disabled):hover {
          filter: brightness(35%) sepia(100%) hue-rotate(172deg) saturate(2000%); // fallback if new css variables are not supported by browser
          filter: brightness(35%) sepia(100%) hue-rotate(var(--hue-value)) saturate(2000%);
        }
      }

      .EmptyList {
        align-self: stretch;
        flex: 1;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }

        > img {
          max-width: 60px;
          max-height: 60px;
          margin-bottom: 10px;
          filter: brightness(0) invert(1);
          user-select: none;
        }

        > p {
          margin-top: 2px;
          margin-bottom: 0;

          &.extraBold {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }

    .InsertLabelNamesPopupContent {
      margin-left: 40px;
      margin-right: 10px;
      margin-top: 20px;

      .LabelEntry {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: space-between;

        .ImageButton {
          margin-right: 15px;

          img {
            filter: brightness(0) invert(1);
          }

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}