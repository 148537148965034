@import '../../settings/_Settings';

.MainView {
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;

  background-image: linear-gradient(to top right, $primaryColor, $secondaryColor);
  background-size: 400% 400%;
  animation: backgroundAnimation 10s ease-in-out infinite;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &.InProgress {
    .LeftColumn {
      width: 280px;
      height: 100%;
      transition: width 1.2s ease-out;
    }

    .Slider {
      width: 280px;
    }

    #lower {
      transition: width 1.5s ease-out;
    }

    #upper {
      transition: width 1.4s ease-out;
    }

    .RightColumn {
      .ImagesDropZone {
        animation-duration: 1.5s;
        animation-name: cssAnimationShow;
        animation-fill-mode: forwards;
      }
    }

    .LeftColumn {
      .EditorFeaturesWrapper {
        animation-duration: 0.6s;
        animation-name: cssAnimationHide;
        animation-fill-mode: forwards;
      }

      .TextButton {
        box-shadow: white 0 0 0 2px inset;
        color: white;
        position: absolute;
        left: 20px;
        bottom: 20px;

        &:hover {
          background-color: white;
          color: black;
        }
      }
    }
  }

  &.Canceled {
    .RightColumn {
      .ImagesDropZone {
        animation-duration: 0.6s;
        animation-name: cssAnimationHide;
        animation-fill-mode: forwards;
      }
    }

    .LeftColumn {
      .EditorFeaturesWrapper {
        animation-duration: 1.5s;
        animation-name: cssAnimationShow;
        animation-fill-mode: forwards;
      }
    }
  }
}

.Slider {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 0;
  width: 75%;
  height: 100%;

  .TriangleVerticalContent{
    background-color: rgba(0, 0, 0, 0.3);
  }
}

#lower {
  transition: width 1.2s ease-out;
}

#upper {
  transition: width 1.4s ease-out;
}

.LeftColumn {
  position: relative;
  background-color: $darkThemeFirstColor;
  padding: 20px;
  z-index: 100;

  box-sizing: border-box;
  transition: width 1.5s ease-out;
  min-width: 260px;
  align-self: stretch;
  width: 75%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  .LogoWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > img {
      width: 220px;
      user-select: none;
    }
  }

  .EditorFeaturesWrapper {
    flex: 1;
    opacity: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.RightColumn {
  padding: 20px;
  align-self: stretch;
  flex: 1;
  min-width: 200px;
  z-index: 100;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  .TextButton {
    box-shadow: black 0 0 0 2px inset;
    color: black;

    &:hover {
      background-color: black;
      color: white;
    }
  }

  .ImagesDropZone {
    overflow: hidden;
    width: 0;
  }

  > .TextButton {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}

.TriangleVertical {
  width: 200px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -200px;
  background-color: transparent;
  overflow: hidden;
}
.TriangleVerticalContent{
  width: 200px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: skew(-6deg) translateX(-50%);
  background-color: $darkThemeFirstColor;
}

.EditorFeaturesTiles {
  flex: 1 0 26%;
  min-width: 200px;
  height: 200px;
  color: white;


  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .EditorFeaturesTilesWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    transition: transform .2s;

    &:hover {
      transform: scale(1.02);
    }

    .EditorFeatureLabel {
      width: 180px;
      text-align: center;
      user-select: none;
    }

    > img {
      filter: brightness(0) invert(1);
      max-width: 60px;
      max-height: 60px;
      margin-bottom: 20px;
      user-select: none;
    }
  }
}

@keyframes cssAnimationShow {
  0%   {
    opacity: 0;
    width: 0;
  }
  60%  {
    opacity: 0;
    width: 400px;
  }
  100% {
    opacity: 1;
    width: 400px;
  }
}

@keyframes cssAnimationHide {
  0%   {
    opacity: 1;
    width: 400px;
  }
  40%  {
    opacity: 0;
    width: 400px;
  }
  100% {
    opacity: 0;
    width: 0;
  }
}

@keyframes backgroundAnimation {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}